@import url('https://js.arcgis.com/4.22/esri/themes/light/main.css');

html,
body,
#root,
.container-fluid,
#mapId {
  width: 100% !important;
  height: 100% !important;
}

.map-wrapper {
  position: absolute;
  top: 70px;
  bottom: 65px;
  left: 260px;
  right: 0;
  margin-left: 0px;  /* changed from 24px 11/4/2022 to remove gray border @ left */
  margin-right: 0px; /* changed from 24px 11/4/2022 to remove gray border @ right */
  overflow: hidden;
}

#infoDiv {
  background: white;
  padding: 10px;
}

.main-content-wrapper-scrollable {
  position: absolute;
  top: 70px;
  bottom: 65px;
  left: 280px;
  right: 25px;
  overflow: hidden;
  overflow-y: auto;
}

.hide-footer {
  visibility: hidden;
}

@media screen and (max-width: 1024px) {
  .map-wrapper {
    left: 70px !important;
  }
  .main-content-wrapper-scrollable {
    left: 80px !important;
  }
}
@media screen and (max-width: 736px) {
  .map-wrapper {
    left: 0 !important;
  }
  /* .main-content-wrapper-scrollable {
    left: 80px !important;
  } */
  .esri-ui-top-right > .esri-search {
    display: none !important;
  }
}

#mapId {
  z-index: 0 !important;
}

.leaflet-control-layers-toggle {
  width: 30px !important;
  height: 30px !important;
}

.main-container {
  height: 100%;
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  -webkit-flex-direction: row;
  -webkit-align-content: stretch;
  align-content: stretch;
}

.left {
  -webkit-flex: 1;
  flex: 1;
  position: relative;
}

.right {
  position: relative;
  width: 400px;
}

/* .child {
  background-color:steelblue;
  position:absolute;
  width:100%;
  height:100%;    
} */

.display-content-scroller {
  position: absolute;
  left: 0;
  right: 0;
  top: 76px;
  bottom: 55px;
  overflow: auto;
  scrollbar-width: thin;
}

.map-display-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.btn-resource-delete {
  cursor: pointer;
}
.btn-resource-delete:hover {
  color: #de1212;
}

.add-resources-nav {
  padding: 0 !important;
}

textarea {
  resize: vertical !important;
}

.active-work-order {
  background-color: #0c3a54 !important;
  color: #fff !important;
}

.btn-work-order-detail-hidden {
  visibility: hidden !important;
}

.user-initials-height {
  max-height: 70px !important;
  max-width: 70px !important;
}

.user-initials {
  width: 40px;
  height: 40px;
  display: flex;
  position: relative;
  overflow: hidden;
  background-color: rgb(114, 124, 245);
  color: rgb(255, 255, 255);
  font-size: 1.25rem;
  flex-shrink: 0;
  -webkit-box-align: center;
  align-items: center;
  user-select: none;
  border-radius: 50%;
  -webkit-box-pack: center;
  justify-content: center;
  margin-left: -40px;
}

.work-create-loader {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  background-color: #000;
  z-index: 45;
  opacity: 0.7;
}
.work-create-loader-text {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 40%;
  text-align: center;
  color: #fff;
  font-size: 1.5rem;
  z-index: 50;
}
.work-creation-loader {
  display: none;
}

.app-search span.search-icon {
  left: 65px !important;
}

.hide-leftside-menu {
  width: 0 !important;
}
.hide-rightside-panel {
  width: 0 !important;
}
.footer-full-width {
  left: 0 !important;
}
.map-wrapper-full-width {
  left: 0 !important;
}

/* Basemap toggler */
.esri-widget.esri-basemap-toggle .esri-widget.esri-basemap-toggle,
.esri-ui-corner .esri-widget.esri-basemap-toggle {
  border: 2px solid #fff;
}
.esri-basemap-toggle__image--secondary {
  border: 2px solid #fff;
}

.flex-row {
  width: 400px;
  display: flex;
  flex-direction: row;
  background: #313a46;
  height: 70px;
  padding: 5px 5px;
}

.box {
  color: white;
  text-align: center;
}

.box--start {
  font-size: 1.6rem;
  font-weight: 400;
  color: #afadad;
  line-height: 65px;
}

.box--end {
  width: 130px;
  margin-left: auto;
  line-height: 60px;
}

.workOrders-total {
  float: right;
  margin-top: 8px;
  margin-right: 5px;
  font-size: 1.8rem;
  font-weight: 400;
  color: #afadad;
}

.map-click-location {
  color: #fff;
  background-color: #3f51b5 !important;
  border-color: #3f51b5 !important;
}

.esri-sketch__section.esri-sketch__tool-section {
  /* display: none !important; */
}
/* button.esri-icon-cursor {
  display: none !important;
} */

.esri-sketch__button,
button.button {
  align-items: center;
  background-color: transparent;
  border: none;
  color: #727cf5; /* #6e6e6e; */
  display: flex;
  font-size: 36px;
  height: 52px;
  justify-content: center;
  text-align: center;
  transition: background-color 125ms ease-in-out;
  width: 52px;
}

.esri-icon-cursor {
  display: none;
}
.esri-sketch__panel:first-child {
  padding: 0;
  margin: 0;
}

#sketch-address-search {
  background: #ddd;
  padding: 10px;
  width: 100% !important;
}

.nav-tabs.nav-bordered li a {
  padding: 0.625rem 1rem !important;
  /* padding: 0.5rem 1rem; */
}

.nav-tabs.nav-bordered li a {
  padding: 0.625rem 0.5rem !important;
}

.wo-batch-toggle-item {
  position: absolute !important;
  left: 10px !important;
  top: 35px !important;
}
.work-list-group-header {
  padding: 0;
  margin: 0;
  margin-left: 15px;
  color: #3470bf;
  font-weight: bold;
}

.list-selected-bg {
  background-color: #daefff !important;
}

.page-break-clear {
  clear: both;
}
.page-break {
  page-break-after: always; /* depreciating, use break-after */
  break-after: page;
  height: 0px;
  display: block !important;
}

@media print {
  .page-break-clear {
    clear: both;
  }
  .page-break {
    page-break-after: always; /* depreciating, use break-after */
    break-after: page;
    height: 0px;
    display: block !important;
  }
}

.content-page-fluid {
  margin-left: 0 !important;
}
.content-page-fluid-wrapper {
  left: 25px !important;
}
.completed-work-detail-bg {  /* COMPLETED WORK BACKGROUND */
  background-color: #d2f4ea !important;
}
.archived-work-detail-bg {  /* ARCHIVED WORK BACKGROUND */
  background-color: #fce0fc !important;
}

.btn-div-edit-department {
  width: 35px;
  height: 35px;
  background-color: #fa5c7c;
  color: #fff;
  text-align: center;
  line-height: 35px;
  border-radius: 35px;
  float: right;
  cursor: pointer;
}
.btn-div-edit-department:hover {
  background-color: #df889a;
}

#long-task-loader-bg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000;
  opacity: 0.7;
  z-index: 450000;
}

#long-task-loader-text {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1.5rem;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  color: #fff;
  z-index: 460000;
}

.active-asset {
  background-color: #ecfbfd !important;
}

.esri-track {
  position: absolute !important;
  bottom: 108px !important;
}
.esri-home {
  position: absolute !important;
  bottom: 70px !important;
}

.esri-expand--auto {
  position: absolute !important;
  bottom: 75px !important;
}

.street-view-widget-wrapper {
  width: 400px !important;
  min-height: 400px !important;
  overflow: auto;
  background-color: #ccc;
}
#btn-close-street-view:hover {
  background-color: #888fe3;
}

#street-view-btn {
  box-shadow: none !important;
}
#googleStreetViewBtn {
  background-color: transparent;
  background-image: url('pegman.png');
  background-size: 32px 32px;
  background-repeat: no-repeat;
  cursor: grabbing;
  position: absolute !important;
  bottom: 145px !important;
  box-shadow: none !important;
}

.cctv-anoucement {
  padding: 15px;
  font-size: 0.8rem;
}
.card-header {
  border-radius: 0 !important;
}
.cctv-title {
  float: left;
  font-size: 1.9rem;
  font-weight: 400;
  color: #afadad;
  margin: 15px 5px;
}

.video-player-wrapper {
  max-width: 400px;
}
.cctv-item-style {
  margin-bottom: 10px;
}
.work-location-btn-active {
  background-color: #505be9 !important;
  color: #fff !important;
}

.player-wrapper {
  position: relative;
  margin: 0;
  padding: 0;
  height: 300px;
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}
