.swal-button {
    padding: 7px 19px;
    border-radius: 2px;
    background-color: #4962b3;
    font-size: 12px;
    border: 1px solid #3e549a;
    text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.3);
}

.swal-button--cancel {
    color: #fff;
    background-color: #723a3f !important;
    border: 1px solid #723a3f !important;
}

.btn-delete-asset {
    float: right;
    margin-top: -40px;
    font-size: 1.5rem;
    font-weight: 400;
    color: #e25e5e;
}
.btn-delete-asset:hover {
    cursor: pointer;
    color: #b53030;
}
