.panel-side {
  padding: 2px;
  box-sizing: border-box;
  width: 300px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.6);
  overflow: auto;
  z-index: 60;
}

.panel-side h5 {
  padding: 0 20px;
  margin: 10px 0;
}

.panel-side ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.panel-side li {
  list-style: none;
  padding: 8px 20px;
}

.panel-result {
  cursor: pointer;
  margin: 2px 0;
  background-color: rgba(0, 0, 0, 0.3);
}

.panel-result:hover,
.panel-result:focus {
  color: orange;
  background-color: rgba(0, 0, 0, 0.75);
}

@media all {
  .page-break {
    display: none;
  }
}

@media print {
  .page-break {
    display: block;
    page-break-before: always;
  }
}
